<template>
  <section>
    <NavBody>
      <template #body>
        <p class="font-normal text-xl text-white/80 lg:text-center uppercase">Welcome {{ userDetails.name }}</p>
        <p class="font-normal text-lg xl:text-3xl text-white lg:text-center font-cooper" v-if="allJobs">
          We have {{ JobsData?.meta?.total }} opportunities for your next step.
        </p>
      </template>
    </NavBody>
    <div class="lg:px-20 px-5 py-5 mt-10">
      <form class="grid gap-4 md:flex mb-10">
        <!--<div class="relative md:w-1/5">
          <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <Search />
          </div>
          <input
            type="keyword"
            v-model="keyword"
            id="keyword"
            :name="keyword"
            class="border text-sm placeholder:text-brand-placholder rounded-md w-full pl-10 p-3 appearance-none focus:outline-none border-brand-black/10 focus:ring-0 focus:border-brand-primary"
            placeholder="Keywords..."
            required=""
          />
        </div>-->
        <div class="relative md:w-1/4">
          <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <Search />
          </div>
          <input
            data-test="search"
            type="keyword"
            v-model="filter.search"
            id="keyword"
            class="bg-brand-light/10 text-base rounded-[10px] text-brand-black block pl-10 p-3 w-full appearance-none focus:outline-none border border-brand-light focus:border-brand-dark focus:border focus:ring-1"
            placeholder="Keywords..."
            required=""
          />
        </div>
        <div class="relative md:w-1/4">
          <Select place-holder="Type" :options="enums.job_types" v-model="filter.type" />
        </div>
        <div class="relative md:w-1/4">
          <Select place-holder="Work type" :options="enums.job_work_types" v-model="filter.work_type" />
        </div>
        <Button data-test="filter-jobs-button" label="Filter Jobs" color="primary" size="4px" class="md:w-1/4" @click="getJobs" />
      </form>
      <div>
        <Card data-test="" :details="allJobs" v-if="pageLoaded" />
        <Loading :is-loading="!pageLoaded" className="flex justify-center" />
        <Paginate
          data-test="paginate"
          @next="
            currentPage += 1;
            getJobs();
          "
          @prev="
            currentPage > 1 ? (currentPage -= 1) : currentPage;
            getJobs();
          "
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import Search from '@/assets/icons/search.svg?inline';
import Button from '@/components/Button.vue';
import Select from '@/components/form/Select.vue';
import Input from '@/components/form/Input.vue';
import Card from '@/components/jobCard.vue';
import NavBody from '@/components/NavBody.vue';
import Paginate from '@/components/Paginate.vue';
import { ref, computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { errorMessage, alert } from '@/utils/helper';
import Loading from '@/components/Loading.vue';

const store = useStore();
const router = useRouter();

const pageLoaded = ref(false);
const currentPage = ref(1);

const filter = ref({
  search: '',
  type: '',
  work_type: '',
});

const JobsData = computed(() => {
  return store.getters['global/getAllJobs'];
});

const allJobs = computed(() => {
  return store.getters['global/getJobs'];
});

const userDetails = computed(() => store.getters['auth/userDetails']);

const getJobs = async () => {
  pageLoaded.value = false;
  try {
    await store.dispatch('global/getAllJobs', { page: currentPage.value, ...filter.value });
    pageLoaded.value = true;
  } catch (error) {
    alert(errorMessage(error), 'error');
    pageLoaded.value = true;
    if (error.response.status === 409) router.push('/register-verify');
  }
};

const enums = computed(() => {
  return store.state.global.EnumDetails;
});

/* const categories = computed(() => {
  return store.state.global.jobCategories;
}); */

onBeforeMount(async () => {
  getJobs();
  await store.dispatch('global/getEnums');
  await store.dispatch('global/getJobCategories');
  await store.dispatch('global/getJobCategories');
});
</script>
