<template>
  <section>
    <div data-test="job-card-section" v-for="(detail, idx) in details" :key="idx">
      <div class="border border-brand-black/5 cursor-pointer rounded-xl lg:p-6 p-3 flex flex-col md:flex-row justify-between mb-4 lg:space-x-32">
        <div class="flex items-center" @click="viewJob(detail)">
          <img :src="detail.employer.logo_url" alt="logo" class="lg:w-12 lg:h-12 h-7 w-7 rounded-full object-cover mr-2" />
          <div class="flex flex-col">
            <p data-test="role-detail" class="font-albertBold lg:text-xl text-base text-brand-black text-justify capitalize">
              {{ role(detail) }}
            </p>
            <p data-test="employer-name" class="text-sm font-albert text-[#12080F] text-opacity-70 capitalize order-first lg:order-last">
              {{ detail.employer.name }}
            </p>
          </div>
        </div>
        <div class="lg:flex justify-between grid items-center">
          <div class="lg:text-right lg:shrink-0 col-span-2 flex lg:block gap-4 items-center" @click="viewJob(detail)">
            <p data-test="role-location" class="font-normal lg:text-lg text-sm text-gray-500 capitalize whitespace-nowrap">
              {{ detail.type.split('_').join('-') }} | {{ detail.work_type.split('_').join('-') }}
            </p>
            <p class="text-2xl text-[#12080F]/50 lg:hidden mb-2.5">.</p>
            <p data-test="salary" class="lg:text-base text-sm font-albertSemiBold text-brand-black/90 whitespace-nowrap">
              {{ formatNumber(detail?.salary_min) || detail?.job.salary_min?.formatted }} -
              {{ formatNumber(detail?.salary_max) || detail?.job.salary_max?.formatted }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { formatNumber, formatTitle } from '@/utils/helper';

const router = useRouter();
const props = defineProps({
  details: { type: Array },
  viewType: { type: String },
  path: String,
});

const role = (detail) => detail.role_title || detail.job.title_name;

const viewJob = (job) => {
  router.push(`/${formatTitle(job?.employer?.name)}/${job?.fid}/${formatTitle(job?.role_title)}`);
};
</script>
